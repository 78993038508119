<template>
    
      <!-- Main content -->
      <section class="content">
        <div class="btn-box">
            <el-form :inline="true">
                <el-form-item label="商品id：">
                    <el-input v-model="itemId" placeholder="请输入商品itemId"></el-input>
                </el-form-item>
                <el-form-item label="商品标题">
                    <el-input v-model="title" placeholder="请输入商品标题"></el-input>
                </el-form-item>
                <el-form-item label="平台">
                    <el-select v-model="platform" placeholder="请选择">
                        <el-option
                        v-for="item in platformList"
                        :key="item.type"
                        :label="item.name"
                        :value="item.type">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="seach(1)">搜索</el-button></el-button> 
                </el-form-item>
            </el-form>
        </div>
        <el-table
            v-loading="loading"
            :data="goodsList"
            border>
            <el-table-column
                fixed
                prop="item_id"
                label="商品itemId"
                width="150"
            >
            </el-table-column>
            <el-table-column
                fixed
                prop="item_title"
                label="商品标题"
                width="280"
            >
            </el-table-column>
            <el-table-column label="商品图片">
                    <template slot-scope="scope">
                        <el-image :src="scope.row.item_pic" :preview-src-list="[scope.row.item_pic]"></el-image>
                    </template>
</el-table-column>
<el-table-column label="平台">
    <template slot-scope="scope">
        {{scope.row.platform | platform}}
    </template>
</el-table-column>
<el-table-column prop="price" label="价格"></el-table-column>
<el-table-column prop="end_price" label="券后价"></el-table-column>
<el-table-column prop="commission_rate" label="佣金比例"></el-table-column>
<el-table-column prop="coupon_amount" label="优惠券金额"></el-table-column>
<el-table-column prop="month_sales" label="月销量"></el-table-column>
</el-table>
<el-pagination background layout="total, sizes,prev, pager, next,jumper" :total="total" @size-change="sizeChange" @current-change="currentChange">
</el-pagination>

</section>
<!-- /.content -->
</template>

<script>
    let common = JSON.parse(window.localStorage.getItem('common'))
    import * as api from '@/config/api'
    export default {
        name: 'goodsList',
        components: {},

        data() {
            return {
                goodsList: [],
                platformList: common.item_list_platform,
                total: 0,
                page: 1,
                pagesize: 10,

                itemId: '',
                title: '',
                platform: '',
                loading: true
            }
        },
        watch: {},
        filters: {
            platform(val) {
                let name = ''
                if (JSON.stringify(common.item_list_platform).indexOf(val) != -1) {
                    common.item_list_platform.map((a, index) => {
                        if (val === a.type) {
                            name = a.name
                        }
                    })
                }
                return name
            },
            dateTime(val) {
                let day = new Date(Number(val) * 1000)
                if (Number(val) === 0) {
                    return ''
                }
                return day.getFullYear() + '-' + `${(day.getMonth() + 1) < 10 ? '0' + (day.getMonth() + 1) : (day.getMonth() + 1)}` + '-' + `${day.getDate()<10?'0'+day.getDate():day.getDate()}` + ' ' + `${day.getHours()<10?'0'+day.getHours():day.getHours()}` + ':' + `${day.getMinutes()<10?'0'+day.getMinutes():day.getMinutes()}` + ':' + `${day.getSeconds()<10?'0'+day.getSeconds():day.getSeconds()}`
            }
        },
        mounted() {
            this.getGoodsDetailList()
        },
        methods: {
            getGoodsDetailList(page, size) {
                this.loading = true
                api.getGoodsDetailList({
                    item_id: this.itemId,
                    title: this.title,
                    platform: this.platform,
                    page: page ? page : this.page,
                    pagesize: size ? size : this.pagesize
                }, res => {
                    this.goodsList = res.data.list
                    this.total = Number(res.data.count)
                    this.loading = false
                })
            },
            sizeChange(val) {
                this.pagesize = val
                this.getGoodsDetailList()
            },
            currentChange(val) {
                this.page = val
                this.getGoodsDetailList()
            },
            seach(page, size) {
                this.getGoodsDetailList(page, size)
            }
        }
    }
</script>
<style scoped>
    .cell {
        text-align: center;
    }
    
    .btn-box {
        text-align: left;
        margin-bottom: 20px;
    }
    
    .el-image img {
        height: 40px;
    }
    
    .el-image-viewer__canvas img {
        width: auto;
        height: auto;
    }
</style>